import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import {
    BrowserRouter as Router,
    Route,
    Switch
} from "react-router-dom";
import Loader from 'react-loader-spinner';

import { isAuth } from '../actions/application.actions';
import { checkLocalStorageCartOnAppLoad } from '../../cart/actions/cart.actions';

import './app.css'

import PrivateRoute from '../components/routing/PrivateRoute.component';
import NotFound from '../components/routing/NotFound.component';
import Header from './header/Header.container';
import Footer from '../components/footer/Footer.component';
import Login from './login/Login.container';
import LostPassword from './lostPassword/LostPassword.container';
import ChangePassword from './changePassword/ChangePassword.container';
import AdminContainer from '../../admin/containers/Admin.container';

// Handlowe
import ClientsContainer from '../../clients/containers/Clients.container';
import ClientContainer from '../../clients/containers/Client.container';
import SellerOrdersContainer from '../../sellerOrders/containers/SellerOrders.container';
import SellerOrderContainer from '../../sellerOrder/containers/SellerOrder.container';

// Klienckie
import ClientDashboardContainer from '../../client/containers/clientDashboard/ClientDashboard.container';
import ProductsContainer from '../../products/containers/Products.container';
import ProductContainer from '../../product/containers/Product.container';
import CartContainer from '../../cart/containers/Cart.container';
import CartSummaryContainer from '../../cart/containers/CartSummary.container';
import OrdersContainer from '../../orders/containers/Orders.container';
import OrderContainer from '../../order/containers/Order.container';
import InvoicesContainer from '../../invoices/containers/Invoices.container';
import InvoiceContainer from '../../invoice/containers/Invoice.container';
import MyAccountContainer from '../../myAccount/containers/MyAccount.container';
import WelcomePageContainer from '../../application/containers/welcomePage/WelcomePage.container';

function App({ isLoading, isAuth, checkLocalStorageCartOnAppLoad, user }) {

    useEffect(() => {
        isAuth();
    }, [isAuth])

    useEffect(() => {
        if (user) {
            checkLocalStorageCartOnAppLoad();
        }
    }, [checkLocalStorageCartOnAppLoad, user]);

    return (
        <div>
            <Router>
                <Header

                />

                <Loader
                    className="loader"
                    type="Puff"
                    color="#252525"
                    height={80}
                    width={80}
                    visible={isLoading}
                />

                {

                    <Switch>
                        {/* Logowanie */}
                        <Route path="/login" exact>
                            <Login />
                        </Route>
                        <Route path="/lost-password" exact>
                            <LostPassword />
                        </Route>
                        <Route path="/changePassword/:id" exact={true}>
                            <ChangePassword />
                        </Route>

                        {/* Welcome page */}
                        <Route path="/" exact={true}>
                            <WelcomePageContainer />
                        </Route>

                        {/* Admin */}
                        <PrivateRoute path="/admin" exact={false} user={user} role='admin' isLoading={isLoading}>
                            <AdminContainer />
                        </PrivateRoute>

                        {/* Handlowe */}
                        <PrivateRoute path="/seller-clients" exact={true} user={user} role='salesman' isLoading={isLoading}>
                            <ClientsContainer />
                        </PrivateRoute>
                        <PrivateRoute path="/client/:id" exact={true} user={user} role='salesman' isLoading={isLoading}>
                            <ClientContainer />
                        </PrivateRoute>
                        <PrivateRoute path="/seller-orders" exact={true} user={user} role='salesman' isLoading={isLoading}>
                            <SellerOrdersContainer />
                        </PrivateRoute>
                        <PrivateRoute path={["/seller-order/:id"]} exact={true} user={user} role='salesman' isLoading={isLoading}>
                            <SellerOrderContainer />
                        </PrivateRoute>

                        {/* Klienckie */}
                        <PrivateRoute path="/dashboard" exact={true} user={user} role='company' isLoading={isLoading}>
                            <ClientDashboardContainer />
                        </PrivateRoute>
                        <PrivateRoute path={["/products/:id", "/products"]} exact={true} user={user} role='company' isLoading={isLoading}>
                            <ProductsContainer />
                        </PrivateRoute>
                        <PrivateRoute path="/product/:id" exact={true} user={user} role='company' isLoading={isLoading}>
                            <ProductContainer />
                        </PrivateRoute>
                        <PrivateRoute path="/cart" exact={true} user={user} role='company' isLoading={isLoading}>
                            <CartContainer />
                        </PrivateRoute>
                        <PrivateRoute path="/cart-summary" exact={true} user={user} role='company' isLoading={isLoading}>
                            <CartSummaryContainer />
                        </PrivateRoute>
                        <PrivateRoute path="/orders" exact={true} user={user} role='company' isLoading={isLoading}>
                            <OrdersContainer />
                        </PrivateRoute>
                        <PrivateRoute path="/order/:id" exact={true} user={user} role='company' isLoading={isLoading}>
                            <OrderContainer />
                        </PrivateRoute>
                        <PrivateRoute path="/invoices" exact={true} user={user} roles={['company', 'salesman']} isLoading={isLoading}>
                            <InvoicesContainer />
                        </PrivateRoute>
                        <PrivateRoute path="/invoice/:id" exact={true} user={user} roles={['company', 'salesman']} isLoading={isLoading}>
                            <InvoiceContainer />
                        </PrivateRoute>
                        <PrivateRoute path="/my-account" exact={true} user={user} role='company' isLoading={isLoading}>
                            <MyAccountContainer />
                        </PrivateRoute>

                        <Route component={NotFound} />

                    </Switch>
                }
                <Footer />
            </Router >
        </div>

    );
}


const mapStateToProps = (store) => ({
    isLoading: store.app.isLoading,
    user: store.app.user
});

const mapDispatchToProps = (dispatch) => {
    return {
        isAuth: () => dispatch(isAuth()),
        checkLocalStorageCartOnAppLoad: () => dispatch(checkLocalStorageCartOnAppLoad())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
